table {
    border-collapse: separate;
    border-spacing: 0;
    width: 1px;
    table-layout: fixed;
}

table th, table td {
    width: 130px;
    padding: 8px;
    word-wrap: break-word;
}

table td {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    border-right: 1px solid rgba(224, 224, 224, 1);
}

table tr:nth-child(2n) {
    background: #fbfbfb;
}

.top-left-header {
    background: white;
    border: 1px solid rgba(224, 224, 224, 1);
}

.top-header {
    position: sticky;
    top: 0;
    background: white;
    border-top: 1px solid rgba(224, 224, 224, 1);
    border-bottom: 3px solid #949090;
    border-right: 1px solid rgba(224, 224, 224, 1);
}

.left-header {
    font-weight: bold;
    position: sticky;
    left: 0;
    background: white;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    border-left: 1px solid rgba(224, 224, 224, 1);
    border-right: 3px solid #949090;
}
